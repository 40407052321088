/// <reference path="../types/config.d.ts" />

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { Draggable } from 'gsap/Draggable'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'

gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(Draggable)
gsap.registerPlugin(ScrollToPlugin)

export default class UWG {
  _elements :Array<HTMLElement> = [];

  constructor(el :Array<HTMLElement> | HTMLElement | string){
    if(typeof el === 'string'){
      this._elements = Array.from(document.querySelectorAll(el))
    }else if(el instanceof Array){
      this._elements = el
    }else{
      this._elements = [el]
    }
  }

  static gsap :any = gsap
  static ScrollTrigger :any = ScrollTrigger
  static Draggable :any = Draggable

  static UWGOptionIn :UWGOption = {
    activeClass: 'actived',
    duration: 0.4,
    delay: 0,
    easing: "power4.out",
  }
  static UWGOptionOut :UWGOption = {
    activeClass: 'actived',
    duration: 0.4,
    delay: 0,
    easing: "power4.out",
  }

  static Target(el :HTMLElement | string){
    return new UWG(el);
  }

  GsapMutationBase(
    activeClass: string = UWG.UWGOptionIn.activeClass,
    additionFunc: Function = () => {},
    removeFunc: Function = () => {},
  ){
    const observer = new MutationObserver(records => {
      records.forEach(record => {
        const recordContains = (record.target as HTMLElement).classList.contains(activeClass);
        const recordOldContains = record.oldValue === null ? false : record.oldValue.includes(activeClass);

        if (recordContains && !recordOldContains){
          additionFunc(record.target)
        }else if(!recordContains && recordOldContains){
          removeFunc(record.target)
        }
      })
    })
    Array.from(this._elements).forEach((elm) => {
        observer.observe(elm, {
        attributes: true,
        attributeOldValue: true,
        attributeFilter: ['class'],
      });
    });

  }

  GsapTo(
    activeClass: string = UWG.UWGOptionIn.activeClass,
    gsapToOptions: object = {},
    gsapPresetOption: object = {},
  ){
    this.GsapMutationBase(activeClass, (target :object) => {
      gsap.to(target, gsapToOptions)
    }, (target :object) => {
      gsap.to(target, {
        ...gsapToOptions,
        runBackwards: true,
      })
    })
  }
  
  GsapFrom(
    activeClass: string = UWG.UWGOptionIn.activeClass,
    gsapFromOptions: object = {},
  ){
    this.GsapMutationBase(activeClass, (target :object) => {
      gsap.to(target, gsapFromOptions)
    }, (target :object) => {
      gsap.to(target, {
        ...gsapFromOptions,
        runBackwards: true,
      })
    })
  }

  GsapFromTo(
    activeClass: string = UWG.UWGOptionIn.activeClass,
    gsapFromOptions: object = {},
    gsapToOptions: object = {},
  ){
    this.GsapMutationBase(activeClass, (target :object) => {
      gsap.fromTo(target, gsapFromOptions, gsapToOptions)
    }, (target :object) => {
      gsap.fromTo(target, gsapFromOptions, {
        ...gsapToOptions,
        runBackwards: true,
      })
    })
  }

  GsapSet(
    activeClass: string = UWG.UWGOptionIn.activeClass,
    gsapSetOptions: object = {},
  ){
    this.GsapMutationBase(activeClass, (target :object) => {
      gsap.set(target, gsapSetOptions)
    }, (target :object) => {
      gsap.set(target, gsapSetOptions).invalidate()
    })
  }

  //UWG EXTENTIONS
  static countMotionOptions :CountMotionOption = {
    durationSec: 2.5,
    activateClass: 'actived',
    easing: "power1.out",
  }

  static CountMotion (
    targetSelector: string,
    countMotionOptions: CountMotionOption = UWG.countMotionOptions,
  ) :void {
    const option = {
      ...UWG.countMotionOptions,
      ...countMotionOptions,
    }
    Array.from(document.querySelectorAll(targetSelector)).forEach((c :Element, i :number) => {
      const initial = (c.textContent || '').replace(',', '')
      c.textContent = `${initial}`
      const max = (c as HTMLElement).dataset.uwgCountmotionMax || initial
      const delaySec = (c as HTMLElement).dataset.uwgCountmotionDelaysec || 0
  
      const g = new UWG(c as HTMLElement)
      g.GsapTo(option.activateClass, {
        duration: option.durationSec,
        delay: delaySec,
        innerText: max,
        modifiers: {
          innerText: (innerText :number) => {
            return Math.floor(innerText)
          }
        }
      })
    })
  }



}
